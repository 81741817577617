.footerArea {
    width: 100%;
    margin-top: 100px;
}

.footerAreaToplibrery {
    width: 100%;
}

.footerAreaTopcontainer {
    width: 100%;
    margin: 0 auto;
}

.librery-row {
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    justify-content: center;
    gap: 30px;
}

.li-col-1 {
    flex-basis: 10%;
}

img.logo-img {
    height: 50px;
}

.li-col-2 {
    flex-basis: 75%;
}

.li-col-3 {
    flex-basis: 15%;
}

.line {
    background-color: #9f9a9a;
    width: 100%;
    height: 2px;
}

.sociallinks {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.sociallinks img {
    height: 40px;
}

/*-------footer-----*/

.footerAreaBottomcontainer {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

footer {
    width: 100%;
}

.foot-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.foot-col {
    flex-basis: 24%;
}

h3.foot-head {
    font-size: 20px;
    color: #940deb;
    font-weight: bold;
    margin-bottom: 25px;
}

ul li {
    list-style: none;
    margin-top: 10px;
}

.foot-nav li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    color: #3f0563;
    transition: 0.2s ease-in-out;
}

h3.cont-head {
    font-size: 20px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 25px;
}

p.f-pre {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
}

.foot-nav li a:hover {
    color: #9609ee;
}