.NoticePage {
    height: 100vh;
    padding-top: 114px;
    display: flex;
}

.NoticePage .noticeArea {
    margin: auto;
    width: 95%;
    padding: 0 20px;
    height: 90%;
    border-radius: 10px;
    border: 1px solid #8343FF;
    background-color: #E1D1FF;
}

.noticeTitleArea {
    display: flex;
    align-items: center;
    width: 100%;
    height: 18%;
    border-bottom: 1px solid #8343FF;
}

.noticeTitleArea h1 {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    color: #8343FF;
}

.noticeTitleArea h4 {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #8343FF;
}

.NoticePage .noticeArea p {
    font-size: 18px;
    height: 82%;
    font-weight: bold;
    padding: 20px 0;
}
