.DashboardPage {
    width: 100%;
    height: 100%;
    padding-top: 114px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.DashboardPageLeft {
    background-color: #F4EFFF;
    position: relative;
    height: calc(110vh - 114px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;
    box-shadow: 8px 5px 25px -20px #854BF4;
}

.DashboardPageLeft.minWidth {
    width: 8%;
}

.DashboardPageLeft.maxWidth {
    width: 16%;
}


.DashboardPageRight {
    background-color: #F4EFFF;
    min-height: calc(110vh - 114px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.DashboardPageRight.minWidth {
    width: 84%;
}

.DashboardPageRight.maxWidth {
    width: 92%;
}












.DashboardPageLeftButton {
    position: absolute;
    top: 0px;
    right: -20px;
    height: 40px;
    width: 40px;
    z-index: 1;
}

.DashboardPageLeftButton img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.DashboardPageLeftLink {
    width: 85%;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-size: 25px;
    font-weight: bold;
    border-radius: 10px;
}

.DashboardPageLeftLink:hover {
    background-color: #E1D1FF;
}

.DashboardPageLeftLink.active {
    background-color: #E1D1FF;
}

.DashboardPageLeftLink img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

.DashboardPageLeftLink h1 {
    color: #8343FF;
}




.DashboardPageLeftLink1 {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 10px;
}

.DashboardPageLeftLink1:hover {
    background-color: #E1D1FF;
}

.DashboardPageLeftLink1.active {
    background-color: #E1D1FF;
}

.DashboardPageLeftLink1 img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
}