.ImageGallery {
    padding-top: 80px;
    background-color: #E1D1FF;
}

.topArea {
    height: 88vh;
    width: 100%;
    position: relative;
    background-image: url("../../assets/backgroundImg/authPageBackground.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.topAboutArea {
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 450px;
    padding: 10px;
    width: 40%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border: 2px solid green;
}

.topAboutArea h1{
    font-size: 30px;
    font-weight: bold;
}
.topAboutArea p{
    width: 90%;
    margin-top: 5px;
}










































.galaeryArea {
    margin-top: 50px;
}

.navigationTabBar {
    width: 90%;
    height: 80px;
    border-radius: 50px;
    background-color: #8343FF;
    margin: auto;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}


.navigationTabBar .page-link img {
    height: 40px;
    width: 60px;
}

.navigationTabBar .buttonList {
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 50px;
    margin: 0 20px;
    overflow-x: hidden; 
    scroll-behavior: smooth;
}

.navigationTabBar .buttonList .buttonListBtn {
    background-color: #fff;
    padding: 10px 25px;
    border-radius: 50px;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    min-width: 100px;
}

.buttonListBtn:hover {
    background-color: #E1D1FF;
}





























.galleryWrap {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.galleryWrap .single {
    width: 320px;
    height: 320px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    /* Transition for smooth scaling effect */
    transition: transform 2s ease;
}

.galleryWrap .single img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease;
}

.galleryWrap .single:hover img {
    transform: scale(1.05);
}


.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
    opacity: 1;
}

.sliderWrap .btnClose {
    top: 20px;
    right: 20px;
    font-size: 40px;
}

.sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    font-size: 40px;
}

.sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    font-size: 40px;
}

.fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
