.header {
	background-color: white;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 150px;
	width: 100%;
	z-index: 100;
	color: white;
}
.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	width: 100%;
	padding: 0 2%;
	color: white;
}

.navLinkArea {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
	width: 100%;
	padding: 0 2%;
	color: white;
}

.logo {
	height: 70px;
}
.mobile-logo {
	font-size: 1.875rem;
	font-weight: bold;
	font-family: poppins;
	color: #8343FF;
}

.mobile-logo {
margin-bottom: 50px;
}
.menu {
	display: none;
}

.menu-icon {
	display: block;
	cursor: pointer;
	color: #8343FF;
}

.mobile-menu {
	position: fixed;
	top: 0;
	left: -100%;
	width: 60%;
	height: 100%;
	padding-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #F4EFFF;
	border-right: 1px solid gray;
	transition: left 0.5s ease-in-out;
}



.mobile-menu.open {
	left: 0;
}

.menu .navlink,
.menu .navlinkBell	{
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 20px;
	margin-right: 30px;
	color: #8343FF;
}


.menu .navlink:hover {
	border-bottom: 2px solid #8343FF;
}

.menu .navlink.active {
	border-bottom: 2px solid #8343FF;
}

.menu .Dashboard .link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 178.5px;
	height: 52.1px;
	font-weight: bold;
	font-size: 20px;
	background-color: #8343FF;
	box-shadow: 0px 1px 41px -1px rgba(133,75,244,0.75);
	-webkit-box-shadow: 0px 1px 41px -1px rgba(133,75,244,0.75);
	-moz-box-shadow: 0px 1px 41px -1px rgba(133,75,244,0.75);
	color: #fff;
	border-radius: 50px;
}

.mobile-menu li {
	padding: 1rem;
	border-bottom: 1px solid gray;
	border-radius: 0.5rem;
	transition: background-color 0.3s, color 0.3s;
	cursor: pointer;
	color: #8343FF;
	font-weight: bold;
	font-size: 20px;
	
}

.bellIcon {
	height: 30px;
	margin-left: 100px;
}
.mobile-menu li:hover {
	background-color: #00df9a;
	color: black;
}

@media (min-width: 768px) {
	.menu {
		display: flex;
	}

	.menu li {
		margin: 0 0.5rem;
	}

	.menu-icon {
		display: none;
	}

	.mobile-menu {
		display: none;
	}
}