.BookCard {
    position: relative;
    width: 240px;
    height: 370px;
    border-radius: 15px;
    margin-bottom: 50px;
    background-color: #8343FF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.BookCard img {
    width: 100%;
    height: 99.5%;
    object-fit: cover;
    border-radius: 15px;
}

.BookCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), #000);
    z-index: 1;
}

.BookCard h1,
.BookCard button {
    position: absolute;
    z-index: 2;
}

.BookCard h1 {
    top: 80%; /* Adjusted position to leave room for button */
    left: 50%;
    font-size: 20px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.BookCard button {
    height: 40px; /* Specify a fixed height for the button */
    width: 120px; /* Specify a fixed width for the button */
    bottom: 15px; /* Position it above the card’s bottom */
    top: 100%; /* Adjusted position to leave room for button */
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: white;
    font-size: 16px;
    font-weight: bold;
    background-color: blue;
    text-align: center;
    border: none;
    border-radius: 20px;
    z-index: 2; /* Ensure it stays on top of the gradient */
}
