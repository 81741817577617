.TopImageSlider {
    width: 100%;
}

.TopImgSlider {
    width: 95%;
    margin: auto;
    border-radius: 15px;
    background-color: green;
    overflow: hidden;


}
.TopImageSliderImg {
    width: 100%;
    height: calc(80vh - 150px);
    object-fit: cover; /* Ensures the image fills the container while maintaining aspect ratio */
}






