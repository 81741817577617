.RenewBook {
    height: calc(100vh - 114px);
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  
  .RenewBookLeft, 
  .RenewBookCenter,
  .RenewBookRight {
    width: 30%;
    height: 90%;
    border-radius: 20px;
    background-color: #E1D1FF;
    border: 1px solid #8343FF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }


  .RenewBookLeft img,
  .RenewBookCenter img,
  .RenewBookRight img  {
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin-top: 30px;
  }
  
  .RenewBookLeft h1,
  .RenewBookCenter h1,
  .RenewBookRight h1 {
    font-size: 30px;
    font-weight: bold;
    color: #8343FF;
    margin-top: 20px;
  }

  .leftCardBottomArea {
      width: 90%;
      margin-top: 100px;
  }
  
  .leftCardBottomArea h2 {
      font-size: 20px;
      color: #8343FF;
  }
  
  
  
  .inputCardBottomArea {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: column;
  }
  
  .inputCardBottomInput {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  
  .inputCardBottomInput h2 {
      width: 30%;
      font-size: 20px;
      color: #8343FF;
  }
  
  .inputCardBottomInput input {
      width: 70%;
      height: 40px;
      border: 1px solid #8343FF;
      padding-left: 20px;
      outline: none;
      border-radius: 5px;
  }
  
  .inputCardBottomArea button {
      background-color: #8343FF;
      height: 40px;
      width: 50%;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-top: 10px;
      border-radius: 5px;
  } 