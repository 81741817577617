.AllBooks {
    padding-top: 80px;
    width: 100%;
    padding-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.allBooksAreaTop {
    width: 95%;
    margin: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

.allBooksArea {
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    align-items: center;
    justify-content: center;
}

.allBooksAreaTopSearch {
    height: 50%;
    width: 30%;
    padding: 0 2%;
    font-size: 20px;
    outline: 1px solid black;
    border: 1px solid black;
}
