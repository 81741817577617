.NoticeListArea {
    height: calc(100vh - 114px);
    padding-top: 150px;
}

.NoticeListArea .noticeRow {
    margin: auto;
    margin-top: 20px;
    width: 95%;
    padding: 0 20px;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #8343FF;
    background-color: #E1D1FF;
}

.titleArea {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
}

.titleArea h1 {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    color: #8343FF;
}

.titleArea h4 {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #8343FF;
}

.NoticeListArea .noticeRow h3 {
    font-size: 18px;
    font-weight: bold;
}
