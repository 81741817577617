.NoticeSlidePage {
    width: 100%;
    height: 520px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
}
.about {
    width: 40%;
    height: 520px;
    border-radius: 15px;
    text-align: center;
}
.NoticeSlide {
    width: 40%;
    height: 520px;
    border-radius: 15px;
    text-align: center;
}

.notice-title {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    background-color: #8343FF;
    border-radius: 10px 10px 0 0;
    padding: 10px;
}

.slide {
    background-color: #FFFFFF;
    opacity: 0.5;
    transform: scale(0.7);
    transition: 0.3s;
    border-radius: 10px;
    height: 500px;
    width: 100%;
    border: 1px solid #8343FF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-active {
    opacity: 1;
    transform: scale(1);
}

.notice-heading {
    font-size: 25px;
    font-weight: bold;
    color: #333;
    margin-top: 25px;
}

.notice-date {
    font-size: 18px;
    color: #888;
    margin-bottom: 20px;
}

.notice-content {
    background-color: #ECE2FF;
    padding: 15px;
    width: 80%;
    margin: auto;
    border-radius: 8px;
    font-size: 18px;
    color: #555;
}
.arrow {
    position: absolute; /* Absolute positioning */
    top: 115%; /* Center the arrow vertically */
    transform: translateX(-50%, -50%); /* Center horizontally and vertically */
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8343FF;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    z-index: 1;
}

.arrow.next {
    right: 40%; /* Position the next arrow */
    transform: translateY(-50%, -50%); /* Center horizontally and vertically */

}

.arrow.prev {
    left: 40%; /* Position the previous arrow */
    transform: translateY(-50%, -50%); /* Center horizontally and vertically */

}


.aboutViewBtn {
    background-color: #8343FF;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    font-size: 24px;
    width: 20%;
    height: 10%;
    margin-top: 30px;
}